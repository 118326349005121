import { createTheme } from '@mui/material/styles';
import grey from '@mui/material/colors/grey';
import {
  black,
  white,
  black90,
  greyL,
  greyUL,
  greyM,
  blackM,
  blueL,
  success,
  errorL,
  error,
  errorD,
  warning,
  warningL,
  info,
  blueTransparent,
  whiteTransparent,
  amberTransparent3,
  amberTransparent4,
  amberM,
  blackTransparent,
  blueUL,
} from './colors';

const theme = createTheme({
  filterPanelWidth: 374,
  filterHeaderHeight: 50,
  sidebarCloseWidth: 72,
  headerHeight: 64,
  primarySb: grey[900],
  primarySbSelected: black,
  drawerWidth: 275,
  drawerMapWidth: 326,
  headerMapHeight: 55,
  zIndex: {
    popupLoader: 1,
    appBar: 200,
    drawer: 100,
    parts: 90
  },
  gridGup: {
    main: 24
  },
  palette: {
    success: {
      main: success,
    },
    error: {
      light: errorL,
      main: error,
      dark: errorD
    },
    warning: {
      light: warningL,
      main: warning,
    },
    info: {
      main: info,
    },
    sidebar: {
      active: amberM,
      main: black,
    },
    grey: {
      lightUL: greyUL,
      light: greyL,
      middle: greyM,
      dark: grey[600],
    },
    amber: {
      middle: amberM,
    },
    blue: {
      lightUL: blueUL,
      middle: blueL,
    },
    white,
    black,
    black90,
    blackM,
    blueTransparent,
    whiteTransparent,
    amberTransparent3,
    amberTransparent4,
    blackTransparent,
    primary: { main: blueL, contrastText: white },
  },
});
export default theme;
