import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  root: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
  },
  logoContainer: {
    height: 30,
    display: 'flex',
    '& svg': {
      width: 75,
    },
    '& .title': {
      color: '#212121',
      fontSize: 12,
      whiteSpace: 'normal',
      lineHeight: 1.2,
      display: 'flex',
      alignItems: 'center',
      width: 100,
      paddingLeft: 30,
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 15,
        width: 1,
        height: '100%',
        background: '#a1a1a1',
      },
    },
  },

}));

export default useStyles;
