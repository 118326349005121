import React from 'react';
import PropTypes from 'prop-types';

import {
  TYPE_CHART,
  TYPE_POWER_PLANT,
  STYLES_CHART_SPP,
  STYLES_CHART_WPP
} from 'constant';

import AreaLine from './parts/AreaLine';
import AreaTwoYAxis from './parts/AreaTwoYAxis';
import useStyles from './styles';

function Rechart({
  typePowerPlant, typeChart, chartInfo, formatDate,
}) {
  const classes = useStyles();
  const { nameChart } = chartInfo;

  let stylesChart = {};
  switch (typePowerPlant) {
    case TYPE_POWER_PLANT.SPP:
      stylesChart = STYLES_CHART_SPP;
      break;
    case TYPE_POWER_PLANT.WPP:
      stylesChart = STYLES_CHART_WPP;
      break;
    default:
      break;
  }

  return (
    <div className={classes.root}>
      <div className={classes.title} variant="h3">
        {nameChart}
      </div>
      <div className={classes.chartContainer}>
        {(TYPE_CHART.AREA_LINE === typeChart) && (
        <AreaLine
          chartInfo={chartInfo}
          stylesChart={stylesChart}
          typeChart={typeChart}
          formatDate={formatDate}
        />
        )}
        {(TYPE_CHART.AREA_TWO_Y_AXIS === typeChart) && (
        <AreaTwoYAxis
          chartInfo={chartInfo}
          stylesChart={stylesChart}
          formatDate={formatDate}
        />
        )}
      </div>
    </div>
  );
}

Rechart.propTypes = {
  typePowerPlant: PropTypes.string,
  typeChart: PropTypes.string,
  chartInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ])),
  formatDate: PropTypes.string,
};

Rechart.defaultProps = {
  typePowerPlant: '',
  typeChart: '',
  chartInfo: {},
  formatDate: '',
};

export default React.memo(Rechart);
