export const black = '#000000';
export const blackM = '#282828';
export const black90 = '#212121';
export const white = '#ffffff';
export const greyL = '#cccccc';
export const greyM = '#a1a1a1';
export const greyUL = '#f3f3f3';
export const blueL = '#4499ee';
export const blueUL = '#ecf4fc';
export const success = '#44bb44';
export const errorL = '#fd8f8f';
export const error = '#dd4747';
export const errorD = '#dd4747';
export const warning = '#ff9900';
export const amberM = '#ffe500';
export const warningL = '#fffddd';
export const info = '#2196f3';
export const transparent = 'rgb(255 255 255 / 0%)';
export const blueTransparent = 'rgba(68, 153, 238, 0.05)';
export const yellowTransparent = 'rgba(255, 153, 0, 0.05)';
export const whiteTransparent = 'rgba(255, 255, 255, 0.5)';
export const successTransparent = 'rgba(68, 187, 68, 0.1)';
export const amberTransparent3 = 'rgba(255, 229, 0, 0.3)';
export const amberTransparent4 = 'rgba(255, 229, 0, 0.4)';
export const blackTransparent = 'rgb(0 0 0 / 20%)';
