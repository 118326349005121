import { UNIT_OF_MEASUREMENT } from 'constant';

const configUnit = (type) => {
  switch (type) {
    case UNIT_OF_MEASUREMENT.KEY_WATT:
      return UNIT_OF_MEASUREMENT.VALUE_WATT;
    case UNIT_OF_MEASUREMENT.KEY_KILOWATT:
      return UNIT_OF_MEASUREMENT.VALUE_KILOWATT;
    case UNIT_OF_MEASUREMENT.KEY_MEGAWATT:
      return UNIT_OF_MEASUREMENT.VALUE_MEGAWATT;
    case UNIT_OF_MEASUREMENT.KEY_KILOWATT_HOUR:
      return UNIT_OF_MEASUREMENT.VALUE_KILOWATT_HOUR;
    case UNIT_OF_MEASUREMENT.KEY_MEGAWATT_HOUR:
      return UNIT_OF_MEASUREMENT.VALUE_MEGAWATT_HOUR;
    case UNIT_OF_MEASUREMENT.KEY_WATT_PER_SQUARE_METER:
      return UNIT_OF_MEASUREMENT.VALUE_WATT_PER_SQUARE_METER;
    case UNIT_OF_MEASUREMENT.KEY_METER_PER_SECOND:
      return UNIT_OF_MEASUREMENT.VALUE_METER_PER_SECOND;
    default:
      return '';
  }
};

export default configUnit;
