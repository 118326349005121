import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'calc(100vh - 50px)',
    gap: '12px',
    padding: '24px',
  },
  item: {
    width: 'calc(50% - 12px)',
    height: 'calc(50% - 12px)',
    padding: '12px 24px',
    position: 'relative',
  }
}));

export default useStyles;
