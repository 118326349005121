import {
  blueL, warning, greyM, blueTransparent, yellowTransparent
} from 'theme/colors';

export const KEY_NAME_L1 = 'y1';
export const KEY_NAME_L2 = 'y2';
export const KEY_NAME_AVG_L1 = 'y1Avg';
export const KEY_NAME_AVG_L2 = 'y2Avg';
export const STYLES_CHART_WPP = {
  lineColor: greyM,
  areaColor: blueL,
  areaFill: blueTransparent,
  planColor: blueL,
  factColor: greyM,
};
export const STYLES_CHART_SPP = {
  lineColor: greyM,
  areaColor: warning,
  areaFill: yellowTransparent,
  planColor: warning,
  factColor: greyM,
};
