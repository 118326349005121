import React from 'react';
import { XAxis } from 'recharts';

import * as xDateType from 'shared/typeDataForNameChart';
import { setDateFormat } from 'shared/dateFormat';
import { greyUL } from 'theme/colors';
import CustomizedAxisTick from '../components/CustomizedAxisTick';

function getConfigXAxisValueTooltipByType(type, formatDate) {
  switch (type) {
    case xDateType.STRING:
      return {
        xAxisComponent: (<XAxis
          dataKey="x"
          stroke={greyUL}
          interval={0}
          tick={<CustomizedAxisTick />}
        />),
        labelTooltipRenderer: (label) => label
      };
    case xDateType.DATE:
      return {
        xAxisComponent: (<XAxis
          dataKey="x"
          tickFormatter={(item) => setDateFormat(item, formatDate)}
          stroke={greyUL}
        />),
        labelTooltipRenderer: (label) => setDateFormat(label, formatDate)
      };
    case xDateType.DATE_PERIOD:
      return {
        xAxisComponent: (<XAxis
          dataKey="x"
          stroke={greyUL}
          interval="preserveStartEnd"
          tick={<CustomizedAxisTick typeTick={type} />}
        />),
        labelTooltipRenderer: (label) => {
          if (!label) return;
          const [from = '', to = ''] = label.split('/');
          return `${setDateFormat(from, formatDate)} - ${setDateFormat(to, formatDate)}`;
        }
      };
    default:
      return {
        xAxisComponent: (<XAxis
          dataKey="x"
          stroke={greyUL}
          interval={0}
        />),
        valueTooltipRenderer: (label) => label
      };
  }
}

export default getConfigXAxisValueTooltipByType;
