export const INFORMATION_MESSAGE = {
  NO_DATA: 'No data'
};

export const TITLE = {
  DASHBOARD: 'Dashboard'
};

export const NAME_CHART = {
  SPP_KEY_METRICS: 'SPP key Metrics (live data) Pokrovskaya SPP',
  WPP_KEY_METRICS: 'WPP key Metrics (live data) Primorskaya WPP-1',
  SPP_CUMULATIVE: 'Cumulative Production Pokrovskaya SPP',
  WPP_CUMULATIVE: 'Cumulative Production Primorskaya WPP-1',
};
