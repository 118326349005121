import axios from 'axios';

const axiosApiInstance = axios.create({
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
    lang: 'en'
  }
});

export default axiosApiInstance;
