export const TYPE_POWER_PLANT = {
  WPP: 'wpp',
  SPP: 'spp'
};

export const TYPE_CHART = {
  AREA_LINE: 'areaLine',
  AREA_TWO_Y_AXIS: 'areaTwoYAxis'
};

export const FORMAT_DATE = {
  TIME: 'HH:mm',
  DAY_MONTH: 'dd.MM'
};

export const delayForLiveChart = 60000;
export const delayForCumulativeChart = 600000;

export const UNIT_OF_MEASUREMENT = {
  KEY_WATT: 1,
  KEY_KILOWATT: 2,
  KEY_MEGAWATT: 3,
  KEY_KILOWATT_HOUR: 11,
  KEY_MEGAWATT_HOUR: 12,
  KEY_WATT_PER_SQUARE_METER: 20,
  KEY_METER_PER_SECOND: 21,
  VALUE_WATT: 'W',
  VALUE_KILOWATT: 'kW',
  VALUE_MEGAWATT: 'MW',
  VALUE_KILOWATT_HOUR: 'kWh',
  VALUE_MEGAWATT_HOUR: 'MWh',
  VALUE_WATT_PER_SQUARE_METER: 'W/m²',
  VALUE_METER_PER_SECOND: 'm/s',
  VALUE_KILOWATT_METER_2: 'kW/m²',
};

export * from './text';
export * from './constRechart';
