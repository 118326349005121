import React, { useState, useEffect } from 'react';
import { Paper } from '@mui/material';

import useInterval from 'hooks/useInterval';
import API from 'api';
import Rechart from 'components/Rechart';
import Header from 'components/Header';
import {
  FORMAT_DATE,
  TYPE_CHART,
  TYPE_POWER_PLANT,
  UNIT_OF_MEASUREMENT,
  delayForLiveChart,
  delayForCumulativeChart,
  NAME_CHART
} from 'constant';
import useStyles from './styles';

function App() {
  const classes = useStyles();
  const [{
    insolationAndOutput,
    windSpeedAndPower,
    wppCumulativeProductionBudget,
    sppCumulativeProductionBudget
  }, setState] = useState(
    {
      insolationAndOutput: {},
      windSpeedAndPower: {},
      wppCumulativeProductionBudget: {},
      sppCumulativeProductionBudget: {},
    }
  );
  const [isRunningOtherCharts, setIsRunningOtherCharts] = useState(false);
  const fetchLiveData = () => {
    API.liveLoad().then(({ data } = {}) => {
      setState((prev) => ({
        ...prev,
        insolationAndOutput: data?.spp?.insolationAndOutput,
        windSpeedAndPower: data?.wpp?.windSpeedAndPower
      }));
    });
  };

  const fetchCumulativeData = () => {
    API.wppCumulative().then(({ data = {} } = {}) => {
      setState((prev) => ({
        ...prev,
        wppCumulativeProductionBudget: data,
      }));
    });
    API.sppCumulative().then(({ data = {} } = {}) => {
      setState((prev) => ({
        ...prev,
        sppCumulativeProductionBudget: data,
      }));
    });
  };

  useEffect(() => {
    fetchLiveData();
    fetchCumulativeData();
    setIsRunningOtherCharts(true);
  }, []);

  useInterval(() => {
    fetchLiveData();
  }, isRunningOtherCharts ? delayForLiveChart : null);

  useInterval(() => {
    fetchCumulativeData();
  }, isRunningOtherCharts ? delayForCumulativeChart : null);

  return (
    <>
      <Header />
      <div className={classes.wrap}>
        <Paper className={classes.item} elevation={3}>
          <Rechart
            typePowerPlant={TYPE_POWER_PLANT.SPP}
            typeChart={TYPE_CHART.AREA_TWO_Y_AXIS}
            chartInfo={{
              ...insolationAndOutput,
              nameChart: NAME_CHART.SPP_KEY_METRICS,
              YAxisLabel: UNIT_OF_MEASUREMENT.MEGAWATT_HOUR,
              YAxisLabelR: UNIT_OF_MEASUREMENT.KILOWATT_METER_2
            }}
            formatDate={FORMAT_DATE.TIME}
          />
        </Paper>
        <Paper className={classes.item} elevation={3}>
          <Rechart
            typePowerPlant={TYPE_POWER_PLANT.WPP}
            typeChart={TYPE_CHART.AREA_TWO_Y_AXIS}
            chartInfo={{
              ...windSpeedAndPower,
              nameChart: NAME_CHART.WPP_KEY_METRICS,
              YAxisLabel: UNIT_OF_MEASUREMENT.MEGAWATT,
              YAxisLabelR: UNIT_OF_MEASUREMENT.METER_SECOND
            }}
            formatDate={FORMAT_DATE.TIME}
          />
        </Paper>
        <Paper className={classes.item} elevation={3}>
          <Rechart
            typePowerPlant={TYPE_POWER_PLANT.SPP}
            typeChart={TYPE_CHART.AREA_LINE}
            chartInfo={{
              ...sppCumulativeProductionBudget,
              nameChart: NAME_CHART.SPP_CUMULATIVE,
              YAxisLabel: UNIT_OF_MEASUREMENT.MEGAWATT_HOUR
            }}
            formatDate={FORMAT_DATE.DAY_MONTH}
          />
        </Paper>
        <Paper className={classes.item} elevation={3}>
          <Rechart
            typePowerPlant={TYPE_POWER_PLANT.WPP}
            typeChart={TYPE_CHART.AREA_LINE}
            chartInfo={{
              ...wppCumulativeProductionBudget,
              nameChart: NAME_CHART.WPP_CUMULATIVE,
              YAxisLabel: UNIT_OF_MEASUREMENT.MEGAWATT_HOUR
            }}
            formatDate={FORMAT_DATE.DAY_MONTH}
          />
        </Paper>
      </div>
    </>
  );
}

export default App;
