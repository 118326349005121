import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  CartesianGrid,
  ComposedChart,
  YAxis,
  Tooltip,
  Area,
  Legend,
  Line
} from 'recharts';

import { INFORMATION_MESSAGE, KEY_NAME_L1, KEY_NAME_L2 } from 'constant';
import { greyUL } from 'theme/colors';
import CustomizedTooltip from '../components/CustomizedTooltip';
import getConfigXAxisValueTooltipByType from '../config/ConfigXAxisValueTooltipByType';

import useStyles from '../styles';
import configUnit from '../config/ConfigUnit';

function AreaTwoYAxis({ chartInfo, stylesChart, formatDate }) {
  const classes = useStyles();
  const {
    y1Name, y2Name, data, xDateType = 0,
    y1Unit, y2Unit
  } = chartInfo;
  const {
    lineColor, areaColor, areaFill,
  } = stylesChart;
  const { xAxisComponent, labelTooltipRenderer } = getConfigXAxisValueTooltipByType(xDateType, formatDate);

  return (
    (data) ? (
      <ResponsiveContainer
        className={classes.wrapper}
      >
        <ComposedChart className={classes.chart} data={data}>
          <CartesianGrid stroke={greyUL} vertical={false} />
          {xAxisComponent}
          <YAxis
            label={{
              value: configUnit(y1Unit), angle: -90, position: 'insideRight',
            }}
            tickCount={8}
            yAxisId="right"
            stroke={greyUL}
            orientation="right"
          />
          <YAxis label={{ value: configUnit(y2Unit), angle: -90, position: 'insideLeft' }} tickCount={8} yAxisId="left" stroke={greyUL} />
          <Legend iconType="plainline" />
          <Tooltip
            content={({ payload, label }) => (<CustomizedTooltip label={labelTooltipRenderer(label)} currentData={payload} />)}
          />
          <Area
            yAxisId="right"
            name={y1Name}
            type="monotone"
            dataKey={KEY_NAME_L1}
            stroke={areaColor}
            fill={areaFill}
            strokeWidth={2}
          />
          <Line
            yAxisId="left"
            name={y2Name}
            type="monotone"
            dataKey={KEY_NAME_L2}
            stroke={lineColor}
            strokeWidth={2}
            dot={false}
          />
        </ComposedChart>
      </ResponsiveContainer>
    )
      : INFORMATION_MESSAGE.NO_DATA
  );
}

AreaTwoYAxis.propTypes = {
  stylesChart: PropTypes.objectOf(PropTypes.string),
  chartInfo: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array
  ])),
  formatDate: PropTypes.string,
};

AreaTwoYAxis.defaultProps = {
  chartInfo: {
    y1Name: '',
    y2Name: '',
    data: []
  },
  stylesChart: {
    lineColor: '',
    areaColor: '',
    areaFill: ''
  },
  formatDate: '',
};

export default AreaTwoYAxis;
