import queryString from 'query-string';
import { startOfMonth } from 'date-fns';
import axiosApiInstance from './axiosSettings';

let API_DOMAIN = 'anl001-api-int.k8s-app-dev.dtek.cloud';
if (process.env.REACT_APP_API_URL) {
  API_DOMAIN = process.env.REACT_APP_API_URL;
}

const API_URL = `https://${API_DOMAIN}/api`;

const getMonth = () => {
  const startMonth = startOfMonth(new Date());
  return new Date(startMonth.getTime() - (startMonth.getTimezoneOffset() * 60000)).toISOString();
};
const getCurrentTime = () => {
  const currentTime = new Date();
  return new Date(currentTime.getTime() - (currentTime.getTimezoneOffset() * 60000)).toISOString();
};

const API = {
  wppCumulative: async () => {
    try {
      const wppParams = () => (
        queryString.stringify({
          plantIds: 3,
          from: getMonth(),
          to: getCurrentTime()
        })
      );
      return await axiosApiInstance.get(`${API_URL}/wpp/DailyCumulativeProductionFact?${wppParams()}`);
    } catch (error) {
      console.log(error);
    }
  },

  sppCumulative: async () => {
    try {
      const sppParams = () => (
        queryString.stringify({
          plantIds: 6,
          from: getMonth(),
          to: getCurrentTime()
        })
      );
      return await axiosApiInstance.get(`${API_URL}/spp/DailyCumulativeProductionFact?${sppParams()}`);
    } catch (error) {
      console.log(error);
    }
  },

  liveLoad: async () => {
    try {
      const liveParams = queryString.stringify({
        'Wpp.IncludeWindSpeedAndPower': true,
        'Wpp.PlantIds': 3,
        'Spp.IncludeInsolationAndOutput': true,
        'Spp.PlantIds': 6
      });
      return await axiosApiInstance.get(`${API_URL}/plant/live?${liveParams}`);
    } catch (error) {
      console.log(error);
    }
  },
};

export default API;
