import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function FormanNumber({ value }) {
  return (
    value
      ? (
        <NumberFormat
          value={value}
          displayType="text"
          thousandSeparator={' '}
          decimalSeparator="."
          decimalScale={3}
        />
      )
      : '—'
  );
}

FormanNumber.propTypes = {
  value: PropTypes.number
};
FormanNumber.defaultProps = {
  value: null
};

export default FormanNumber;
