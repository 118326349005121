import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'relative',
  },
  title: {
    fontSize: '1rem',
    color: theme.palette.black90,
    marginBottom: 10,
    fontWeight: 'bold',
    textAlign: 'left',
    paddingRight: 20,
  },
  chartContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  chart: {
    '& .recharts-text': {
      fontSize: 8,
      fontWeight: 400,
      letterSpacing: '0.27px',
      fill: '#8a8a8a',
      '&.recharts-label': {
        fontSize: '0.75rem',
        color: theme.palette.grey.middle,
        fontWeight: 'normal',
      }
    },
  },
  cartesianColor: theme.palette.grey.lightU,
  wrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minHeight: '150px',
  },
}));

export default useStyles;
