import React from 'react';
import PropTypes from 'prop-types';

import {
  ResponsiveContainer,
  CartesianGrid,
  YAxis,
  Tooltip,
  Area,
  Legend,
  AreaChart,
} from 'recharts';

import { greyUL, transparent } from 'theme/colors';
import {
  INFORMATION_MESSAGE, TYPE_CHART, KEY_NAME_L1
} from 'constant';
import CustomizedTooltip from '../components/CustomizedTooltip';
import getConfigXAxisValueTooltipByType from '../config/ConfigXAxisValueTooltipByType';
import useStyles from '../styles';
import configUnit from '../config/ConfigUnit';

function AreaLine({
  chartInfo: {
    y1Name, data,
    xDateType = 0,
    y1Unit,
  },
  stylesChart,
  typeChart,
  formatDate
}) {
  const classes = useStyles();
  const { areaColor, areaFill } = stylesChart;
  const { xAxisComponent, labelTooltipRenderer } = getConfigXAxisValueTooltipByType(xDateType, formatDate);

  return (
    (data)
      ? (
        <ResponsiveContainer
          className={classes.wrapper}
        >
          <AreaChart className={classes.chart} data={data}>
            <CartesianGrid stroke={greyUL} vertical={false} />
            {xAxisComponent}
            <YAxis label={{ value: configUnit(y1Unit), angle: -90, position: 'insideLeft' }} tickCount={8} stroke={greyUL} />
            <Legend iconType="plainline" />
            <Tooltip
              content={({ payload, label }) => (
                <CustomizedTooltip
                  label={labelTooltipRenderer(label)}
                  currentData={payload}
                />
              )}
            />
            <Area
              name={y1Name}
              type="monotone"
              dataKey={KEY_NAME_L1}
              stroke={areaColor}
              fill={typeChart === TYPE_CHART.AREA_LINE ? areaFill : transparent}
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      )
      : INFORMATION_MESSAGE.NO_DATA
  );
}

AreaLine.propTypes = {
  stylesChart: PropTypes.objectOf(PropTypes.string),
  chartInfo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array])
  ),
  typeChart: PropTypes.string,
  formatDate: PropTypes.string,
};

AreaLine.defaultProps = {
  chartInfo: {
    y1Name: '',
    y2Name: '',
    data: [],
    xDateType: 0,
  },
  stylesChart: {
    areaColor: '',
    areaFill: '',
  },
  typeChart: '',
  formatDate: '',
};

export default AreaLine;
