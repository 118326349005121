import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'recharts';
import * as xDateType from 'shared/typeDataForNameChart';
import { setDateFormat } from 'shared/dateFormat';

function CustomizedAxisTick({
  x, y, payload: { value }, typeTick
}) {
  let label = value;

  if (typeTick === xDateType.DATE_PERIOD) {
    const [from = '', to = ''] = value.split('/');
    label = `${setDateFormat(from)} - ${setDateFormat(to)}`;
  }
  return <Text x={x} y={y} width={125} textAnchor="middle" verticalAnchor="start">{label}</Text>;
}

CustomizedAxisTick.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  payload: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])),
  typeTick: PropTypes.number,
};

CustomizedAxisTick.defaultProps = {
  x: null,
  y: null,
  payload: {
    value: ''
  },
  typeTick: 0
};

export default CustomizedAxisTick;
