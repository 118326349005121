import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import FormanNumber from 'components/FormatNumber';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.white,
    boxShadow: `0 2px 4px 0 ${theme.palette.blackTransparent}`,
    textAlign: 'left',
    padding: 16,
    borderRadius: 3,
    '& .title': {
      fontSize: '1rem',
      marginBottom: 5,
    },
    '& .list': {
      listStyle: 'none',
      padding: 0,
      margin: 0
    },
    '& .item': {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.875rem',
      marginTop: 5
    },
    '& .titleItem': {
      marginRight: 24,
    }
  },
}));

function CustomizedTooltip({
  currentData,
  label,
}) {
  const classes = useStyles();

  if (!currentData) return null;

  const renderLi = ({
    dataKey, value, color, name
  }) => (
    <li className="item" key={`item-${dataKey}-${value}`} style={{ color }}>
      <span className="titleItem">
        {name}
        :
      </span>
      <FormanNumber value={value} />
    </li>
  );

  return (
    <div
      className={classes.root}
    >
      <Typography className="title" variant="subtitle2">
        {label}
      </Typography>
      <ul className="list">
        {currentData.map(({
          color, value = 0, name, dataKey = ''
        }) => renderLi({
          dataKey, value, color, name
        }))}
      </ul>
    </div>
  );
}

CustomizedTooltip.propTypes = {
  currentData: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    payload: PropTypes.shape({
      threshold: PropTypes.number
    })
  })),
  label: PropTypes.string,
  tooltip: PropTypes.shape({
    typeTooltip: PropTypes.string,
    tooltipItemText: PropTypes.string,
  }),
};

CustomizedTooltip.defaultProps = {
  currentData: [],
  label: '',
  tooltip: {},
};

export default CustomizedTooltip;
