import React from 'react';
import {
  Typography,
} from '@mui/material';
import { ReactComponent as LogoSvg } from './assets/logo.svg';
import useStyles from './styles';

function Header() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logoContainer}>
        <LogoSvg />
        <Typography className="title" variant="subtitle1" noWrap>
          Dashboard
        </Typography>
      </div>
    </div>
  );
}

export default Header;
